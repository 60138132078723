import React from 'react'
import cn from 'classnames'
import styles from './CardWithButton.module.scss'

export default ({ onClick, title, image, active, classname }) => {
  return (
    <button
      onClick={onClick}
      active={active}
      className={cn(classname, styles.buttonWrapper, 'w-100', {
        active: active
      })}
    >
      {image && (
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${image})` }}
        />
      )}
      <span
        className={cn(
          styles.buttonTitle,
          'mb-0 d-flex align-items-center justify-content-center w-100'
        )}
      >
        {title}
      </span>
    </button>
  )
}
